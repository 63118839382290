import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col" }
const _hoisted_2 = { class: "p-2 pl-8 w-full h-full flex flex-col flex-shrink-0" }
const _hoisted_3 = { class: "md:flex w-full justify-between mb-5" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  class: "mt-5 overflow-y-auto pr-1 h-full"
}
const _hoisted_6 = {
  key: 1,
  class: "h-1/2 w-full flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_role_container = _resolveComponent("role-container")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_add_role_modal = _resolveComponent("add-role-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t("settings.roleAdministration")), 1),
        _createVNode(_component_custom_button, {
          class: "md:w-auto w-full mt-5 md:mt-0 h-10",
          id: 'add-user-button',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddRole = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_icon_plus, {
                color: 'white',
                class: "mr-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("role")), 1)
            ])
          ]),
          _: 1
        })
      ]),
      (_ctx.roles.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
              return (_openBlock(), _createElementBlock("div", {
                key: role.id,
                class: "mt-1"
              }, [
                _createVNode(_component_role_container, {
                  role: role,
                  onEditRole: ($event: any) => (_ctx.editRole(role))
                }, null, 8, ["role", "onEditRole"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_loading_animation, {
              id: 'loadingAnimation',
              name: 'loadingAnimation',
              animationStyle: { radius: '12', animationColor: 'grey' }
            })
          ]))
    ]),
    (_ctx.openAddRole)
      ? (_openBlock(), _createBlock(_component_add_role_modal, {
          key: 0,
          editRole: _ctx.toEditRole,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openAddRole = false), (_ctx.toEditRole = {})))
        }, null, 8, ["editRole"]))
      : _createCommentVNode("", true)
  ]))
}