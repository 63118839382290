
import { RolesInterface } from "@/store/settings/interfaces";
import { defineComponent } from "vue";
import IconEdit from "../../../../components/icons/IconEdit1.vue";

export default defineComponent({
  name: "settingsRoleContainer",
  components: { IconEdit },
  props: {
    role: {
      type: Object as () => RolesInterface,
      required: true,
    },
  },
  emit: ["editRole"],
  setup() {
    return {};
  },
});
