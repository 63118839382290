import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-grey-darkest" }
const _hoisted_2 = { class: "text-sm text-grey" }
const _hoisted_3 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-red ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_openBlock(), _createBlock(_component_centered_modal, {
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close'))),
    title: _ctx.editRole && _ctx.editRole.id ? _ctx.$t('save_role') : _ctx.$t('add_role'),
    cancelButtonText: _ctx.$t('cancel'),
    mainContainerId: 'settings_container'
  }, {
    actionButton: _withCtx(() => [
      _createVNode(_component_custom_button, {
        id: 'save-button',
        class: "max-w-full",
        isDisabled: !_ctx.isAllFilled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createUserRole()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.editRole && _ctx.editRole.id ? _ctx.$t("save") : _ctx.$t("apply")), 1)
        ]),
        _: 1
      }, 8, ["isDisabled"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_input_default, {
        id: 'name',
        name: 'name',
        modelValue: _ctx.role.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.role.name) = $event)),
        type: 'text',
        label: _ctx.$t('role_name'),
        class: "w-full mb-3"
      }, null, 8, ["modelValue", "label"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (permission) => {
        return (_openBlock(), _createElementBlock("div", {
          key: permission?.id,
          class: "ml-1 flex justify-between"
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_1, _toDisplayString(permission.name ? _ctx.$t(permission.name) : "-"), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(permission.description
                                ? _ctx.$t(permission.description)
                                : "-"), 1)
          ]),
          (_ctx.selectedPermissions[permission.id] != null)
            ? (_openBlock(), _createBlock(_component_input_switch, {
                key: 0,
                id: 'switch-' + permission?.name,
                name: 'switch-' + permission?.name,
                modelValue: _ctx.selectedPermissions[permission.id],
                "onUpdate:modelValue": ($event: any) => ((_ctx.selectedPermissions[permission.id]) = $event),
                label: '',
                tabIndex: 1,
                class: "mt-2"
              }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.editRole.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_custom_button, {
              id: 'delete-button',
              class: "max-w-full",
              isPlainButton: true,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeleteModal = true))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_icon_trash, { color: 'red' }),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("delete_role")), 1)
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_delete_modal, {
        open: _ctx.openDeleteModal,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDeleteModal = false)),
        onDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteUserRole())),
        type: 'Rolle'
      }, null, 8, ["open"])
    ]),
    _: 1
  }, 8, ["title", "cancelButtonText"]))
}