
import { defineComponent, onMounted, ref } from "vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import IconPlus from "../../../../components/icons/IconPlus.vue";
import RoleContainer from "./RoleContainer.vue";
import AddRoleModal from "./AddRoleModal.vue";
import loadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import { RolesInterface } from "@/store/settings/interfaces";
import { useRouter } from "vue-router";
import { useRoleStore } from "@/store/settings/roleStore";

export default defineComponent({
    name: "settingsRoleAdministration",
    components: {
        CustomButton,
        IconPlus,
        RoleContainer,
        AddRoleModal,
        loadingAnimation,
    },
    setup() {
        const { roles } = storeToRefs(useRoleStore());
        const { fetchRoles, fetchPermissions } = useRoleStore();
        const toEditRole = ref<RolesInterface>();
        const openAddRole = ref<boolean>(false);
        const router = useRouter();

        function editRole(role: RolesInterface) {
            toEditRole.value = role;

            openAddRole.value = true;
        }
        onMounted(async () => {
            roles.value = [];
            const response = await fetchRoles();
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
            fetchPermissions();
        });
        return { editRole, roles, toEditRole, openAddRole };
    },
});
