import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center w-full p-3 h-9 bg-white-dark hover:bg-grey-lightest rounded-md" }
const _hoisted_2 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_edit = _resolveComponent("icon-edit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.role.name), 1),
    _createElementVNode("div", {
      class: "flex items-center justify-center h-7 w-7 rounded-md hover:bg-grey-light cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editRole')))
    }, [
      _createVNode(_component_icon_edit, { color: 'primary' })
    ])
  ]))
}