
import { computed, defineComponent, onMounted, ref } from "vue";
import CenteredModal from "../../../../components/layout/modals/CenteredModal.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import InputDefault from "../../../../components/inputs/inputDefault/InputDefault.vue";
import InputSwitch from "../../../../components/inputs/inputSwitch/InputSwitch.vue";
import IconTrash from "../../../../components/icons/IconTrash.vue";
import DeleteModal from "../../modals/ConfirmDeleteModal.vue";
import {
    PermissionsInterface,
    RolesInterface,
} from "@/store/settings/interfaces";
import { storeToRefs } from "pinia";
import { cloneData } from "@/common/cloneData";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useRoleStore } from "@/store/settings/roleStore";

export default defineComponent({
    name: "settingsAddRoleModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        InputSwitch,
        IconTrash,
        DeleteModal,
    },
    props: {
        editRole: {
            type: Object as () => RolesInterface,
            default: {} as RolesInterface,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, ctx) {
        const { createRole, updateRole, deleteRole } = useRoleStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { permissions } = storeToRefs(useRoleStore());
        const openDeleteModal = ref(false);
        const role = ref<RolesInterface>({
            name: "",
            permission_ids: [],
        });
        const selectedPermissions = ref<boolean[]>([]);
        const isAllFilled = computed(() => {
            let isFilled = false;
            if (role.value.name) {
                isFilled = true;
            }
            return isFilled;
        });

        onMounted(() => {
            if (props.editRole) {
                role.value = cloneData(props.editRole);
                permissions.value.forEach(
                    (permission: PermissionsInterface) => {
                        selectedPermissions.value[permission.id] = false;
                    }
                );
                if (
                    role.value.permission_ids &&
                    role.value.permission_ids?.length != 0
                ) {
                    role.value.permission_ids?.forEach((permission: number) => {
                        selectedPermissions.value[permission] = true;
                    });
                }
            }
        });

        async function deleteUserRole(): Promise<void> {
            setLoadingId("confirm-delete-button");
            const response = await deleteRole(props.editRole);
            removeLoadingId("confirm-delete-button");
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message: "Rolle wurde erfolgreich gelöscht.",
                    },
                ];
            }
            openDeleteModal.value = false;
            ctx.emit("close");
        }

        async function createUserRole(): Promise<void> {
            const roleData: RolesInterface = {};
            roleData.name = role.value.name;
            roleData.permission_ids = [];
            selectedPermissions.value.forEach((permission, index) => {
                if (permission) {
                    roleData.permission_ids?.push(index);
                }
            });

            setLoadingId("save-button");
            if (props.editRole && props.editRole.id) {
                roleData.id = role.value.id;
                const response = await updateRole(roleData);
                removeLoadingId("save-button");
                if (response?.status == 200) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message: "Rolle wurde erfolgreich aktualisiert.",
                        },
                    ];
                    ctx.emit("close");
                }
            } else {
                const response = await createRole(roleData);
                removeLoadingId("save-button");
                if (response?.status == 201) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message: "Rolle wurde erfolgreich angelegt.",
                        },
                    ];
                    ctx.emit("close");
                }
            }
        }
        return {
            isAllFilled,
            role,
            permissions,
            createRole,
            selectedPermissions,
            createUserRole,
            deleteUserRole,
            openDeleteModal,
        };
    },
});
