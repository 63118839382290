import ApiService from "@/common/api_service";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { RoleStoreStateInterface } from "../interfaces";
import { RolesInterface } from "./interfaces";

export const useRoleStore = defineStore({
    id: "roleStore",
    state: (): RoleStoreStateInterface => ({
        roles: [],
        permissions: [],
    }),
    getters: {},
    actions: {
        /**
         * UPDATE
         */
        async updateRole(role: RolesInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.put({
                resource: "roles",
                params: role,
                slug: role.id,
            });

            if (response?.status == 200) {
                const roleIndex = this.roles.findIndex(
                    (item) => item.id == role.id
                );
                this.roles[roleIndex] = response?.data.data;
            }
            return response;
        },

        /**
         * CREATE
         */

        async createRole(role: RolesInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.post({
                resource: "roles",
                params: role,
            });

            if (response?.status == 201) {
                this.roles.push(response?.data.data);
            }
            return response;
        },

        /**
         * DELETE
         */
        async deleteRole(role: RolesInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.delete({
                resource: "roles/" + role.id,
            });

            if (response?.status == 200) {
                const roleIndex = this.roles.findIndex(
                    (item) => item.id == role.id
                );
                this.roles.splice(roleIndex, 1);
            }
            return response;
        },

        /**
         * FETCH
         */

        async fetchRoles(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "roles",
            });
            this.roles = response?.data.data;
            return response;
        },

        async fetchPermissions(): Promise<void> {
            const response = await ApiService.get({
                resource: "permissions",
            });
            this.permissions = response?.data.data;
        },
    },
});
